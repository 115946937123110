import ContentHero from "@components/ContentHero"
import { Button, makeStyles, Typography } from "@material-ui/core"
import { Link } from "gatsby"
import React from "react"
import Seo from "../components/Seo"
import { homepage } from "../routes"

const usecss = makeStyles((theme) => ({
  button: {
    display: `block`,
    marginTop: theme.spacing(3),
  },
}))

const NotFoundPage = () => {
  const classes = usecss()

  return (
    <>
      <Seo title="404: Not found" description="This page does not exists" />
      <ContentHero
        title={<Typography variant="h1">Not found</Typography>}
        description={
          <>
            <Typography variant="body1">
              You just hit a page that doesn&#39;t exist... the sadness.
            </Typography>
            <Link
              className={classes.button}
              to={homepage}
              style={{ textDecoration: "none" }}
            >
              <Button color="primary" variant="contained">
                Get back to the homepage
              </Button>
            </Link>
          </>
        }
      />
    </>
  )
}

export default NotFoundPage
